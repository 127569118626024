import BaseApi from '@/includes/services/BaseApi'
import { BaseBoardRequest } from "@/includes/types/Api.types";
import { Article, CreatedArticle, PossibleArticleRequestData } from "@/includes/types/Articles.types";

type ArticleKey = Pick<CreatedArticle, 'key'>

export class ArticlesApi {

    static async createArticle(type: string, body: BaseBoardRequest<Article>) {
        return BaseApi.sendRequest<CreatedArticle>(type, 'createarticle', body)
    }

    static async deleteArticle(type: string, body: BaseBoardRequest<ArticleKey>) {
        return BaseApi.sendRequest<ArticleKey>(type, 'deletearticle', body)
    }

    static async getArticle(type: string, body: BaseBoardRequest<ArticleKey>) {
        return BaseApi.sendRequest<CreatedArticle>(type, 'getarticle', body)
    }

    static async updateArticle(type: string, body: BaseBoardRequest<CreatedArticle>) {
        return BaseApi.sendRequest<CreatedArticle>(type, 'updatearticle', body)
    }

    static async getArticlesInfo(type: string, body: BaseBoardRequest) {
        return BaseApi.sendRequest<{items: Array<CreatedArticle>}>(type, 'getarticlesinfo', body)
    }

    static async getArticleRequest(type: string, body: { id: string }) {
        return BaseApi.sendRequest<PossibleArticleRequestData>(type, 'getarticlerequest', body)
    }
}