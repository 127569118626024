import { ChannelInfo } from '@/includes/types/Channel.types'
import { Slot } from '@/includes/types/Slots.types'

export type ChannelsState = {
    activeChannel: ChannelInfo | null,
    copiedSlots: Array<Slot> | null
}

export const channelsState: ChannelsState = {
    activeChannel: null,
    copiedSlots: null
}