import { RootState } from '@/store/state'
import { ChannelInfo, SlimChannelInfo } from '@/includes/types/Channel.types'
import { Slot } from '@/includes/types/Slots.types'

export default {
    set_active_channel_to_store(state: RootState, payload: ChannelInfo): void {
        state.channelsState.activeChannel = payload
    },
    reset_active_channel_from_store(state: RootState): void {
        state.channelsState.activeChannel = null
    },
    reset_slots(state: RootState): void {
        state.channelsState.activeChannel!.config.slots = []
    },
    set_slots_to_channel(state: RootState, payload: Array<Slot>):void {
        state.channelsState.activeChannel!.config.slots = payload
    },
    set_slots_to_copy(state: RootState, payload: Array<Slot>):void {
        state.channelsState.copiedSlots = payload
    },
    update_channel_info(state: RootState, payload: SlimChannelInfo) {
        state.channelsState.activeChannel = Object.assign(state.channelsState.activeChannel, payload)
    }
}