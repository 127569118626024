import { RootState } from '@/store/state'
import { Board, BoardAdmin, SlimBoardInfo } from '@/includes/types/Board.types'
import { TestChannelResultPayload } from '@/mixins/TestStateMixin';

import { BotPmConfig } from 'piramis-base-components/src/shared/modules/BotFeedback/types'

import { assign, merge } from 'lodash'

export default {
    update_board_channel(state: RootState, payload: TestChannelResultPayload) {
        const board = state.boardsState.boards?.find(b => b.board === payload.boardId)

        if (board) {
            const channel = board.channels.find(c => c.id === payload.channel.id)

            if (channel) {
                assign(channel, payload.channel)
            }
        }
    },
    set_boards_to_store(state: RootState, boards: Array<SlimBoardInfo>): void {
        state.boardsState.boards = boards
    },
    set_board_to_store(state: RootState, board: SlimBoardInfo): void {
        const storeBoards = state.boardsState.boards
        const currentBoard = state.boardsState.activeBoard

        if (storeBoards) {
            const index = storeBoards.findIndex((b) => b.board === board.board)

            if (index !== -1) {
                storeBoards.splice(index, 1, board)

                if (currentBoard) {
                    merge(currentBoard, board)
                }

            } else {
                storeBoards.push(board)
            }
        }
    },
    set_active_board_config(state: RootState, payload: Board): void {
        state.boardsState.activeBoard = payload
    },
    clear_active_board_config(state: RootState): void {
        state.boardsState.activeBoard = null
    },
    UPDATE_BOARD_CONFIG_FIELDS(state: RootState, payload: Partial<Board['config']>): void {
        if (state.boardsState.activeBoard) {
            merge(state.boardsState.activeBoard.config, payload)
        }
    },
    update_board_admins(state: RootState, boardAdmins: Array<BoardAdmin>): void {
        if (state.boardsState.activeBoard) {
            state.boardsState.activeBoard.admins = boardAdmins
        }
    },
    update_board_channel_sub_type(state: RootState, payload: any): void {
        const board = state.boardsState.boards!.find(b => b.board === payload.boardKey)

        if (board) {
            const target = board.channels.find(t => t.id === payload.target.id)

            if (target) {
                target.limited_subscription_type = payload.target.limited_subscription_type
            }
        }
    },
    SET_INITIAL_MESSAGE(state: RootState, message: BotPmConfig['initial_message']): void {
        const board = state.boardsState.activeBoard

        if (board) {
            board.config.pm_config.initial_message = message
        }
    },
    SET_AUTO_REPLY_ACTIONS(state: RootState, actions: BotPmConfig['user_auto_reply_actions']): void {
        const board = state.boardsState.activeBoard

        if (board) {
            board.config.pm_config.user_auto_reply_actions = actions
        }
    },
    SET_COMMANDS(state: RootState, actions: BotPmConfig['commands']): void {
        const board = state.boardsState.activeBoard

        if (board) {
            board.config.pm_config.commands = actions
        }
    },
    set_private_message_settings(state: RootState, config: BotPmConfig): void {
        state.boardsState.activeBoard!.config.pm_config = config
    }
}
