import { RootState } from '@/store/state'
import { CreatedArticle } from "@/includes/types/Articles.types";

export default {
    SET_ARTICLES_TO_STORE(state: RootState, articles: Array<CreatedArticle>): void {
        state.articlesState.articles = articles
    },
    CLEAR_ARTICLES_FROM_STORE(state: RootState): void {
        state.articlesState.articles = null
    },
    ADD_ARTICLE(state: RootState, article: CreatedArticle): void {
        if (state.articlesState.articles === null) {
            state.articlesState.articles = []
        }

        state.articlesState.articles.push(article)
    },
    REMOVE_ARTICLE: (state: RootState, articleKey: CreatedArticle['key']) => {
        state.articlesState.articles = state.articlesState.articles!.filter(article => article.key !== articleKey)
    },
    UPDATE_ARTICLE_BY_KEY(state: RootState, newArticle: CreatedArticle): void {
        const idx = state.articlesState.articles!.findIndex(article => article.key === newArticle.key)

        state.articlesState.articles!.splice(idx, 1, newArticle)
    },
}
