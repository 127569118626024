import { RootState } from '@/store/state'
import boardsMutations 	from "./boards/mutations"
import activeChannelMutations from './channel/mutations'
import articlesMutations from './articles/mutations'
import flowsMutations from './flow/mutations'
import notificationMutations from './notifications/mutations'
import statisticsMutations from './statistics/mutations'
import propertiesMutations from './properties/mutations'
import labelsMutations from './labels/mutations'
import shopMutations from './shop/mutations'
import inviteLinksMutations from './InviteLinks/mutations'
import formsMutations from './forms/mutations'
import draftsMutations from './drafts/mutations'
import menuOptionsMutations from './menuOptions/mutations'
import { ChannelInfo } from "@/includes/types/Channel.types";

import { set } from 'lodash'

const mutations = {
	updateTestStateCounter(state:RootState) {
		state.testStateCounter ++
	},
	resetTestStateCounter(state:RootState) {
		state.testStateCounter = 0
	},
	UPDATE_TARGETS_WITHOUT_LINKED_GROUP(state:RootState, payload: Array<ChannelInfo> | null) {
		state.targetsWithoutLinkedGroup = payload
	},
	updateValidateCommentButtonHasGroup(state:RootState, payload:boolean) {
		state.validateCommentButtonHasGroupFields = payload
	},
	setObjectField(state:RootState, { object, objectPath, value }: any) {
		set(object, objectPath, value);
	},
	//posts planner
  	...boardsMutations,
	//active channel
	...activeChannelMutations,
	...articlesMutations,
	...flowsMutations,
	...statisticsMutations,
	...notificationMutations,
	...propertiesMutations,
	...labelsMutations,
	...shopMutations,
	...formsMutations,
	...draftsMutations,
	...menuOptionsMutations,
	...inviteLinksMutations,
};

export default mutations;
