import BaseApi from '@/includes/services/BaseApi'
import {
    ClearUsersGetActiveRequestResponse,
    ClearUsersRequest,
    ClearUsersRequestBody,
} from '../types/ClearUsers.types'
import { TargetFullStat } from '@/includes/types/TargetStat.types'
import { BaseBoardRequest, BaseChannelRequest } from "@/includes/types/Api.types";
import { ChannelSlot } from "@/includes/types/Slots.types";
import { SlimBoardInfo, TargetState } from "@/includes/types/Board.types";
import { ChannelConfig, ChannelInfo, SlimChannelInfo, TestChannelStateCode } from "@/includes/types/Channel.types";

export class ChannelsApi {
    static async updateChannelState(type: string, body: BaseBoardRequest<BaseChannelRequest<{new_state: TargetState}>>) {
        return BaseApi.sendRequest<{ board: Omit<SlimBoardInfo, 'weight' | 'channels'>}>(type, 'updatechannelstate', body)
    }

    static async getChannelConfig(type: string, body: BaseBoardRequest<BaseChannelRequest>) {
        return BaseApi.sendRequest<ChannelInfo>(type, 'getChannelConfig', body)
    }

    static async setChannelConfig(type: string, body: BaseBoardRequest<BaseChannelRequest<{config: ChannelConfig }>>) {
        return BaseApi.sendRequest<ChannelInfo>(type, 'setChannelConfig', body)
    }

    static async getChannelStat(type: string, body: BaseBoardRequest<{ target_id: ChannelInfo['id'], interval: any }>) {
        return BaseApi.sendRequest<{ data: TargetFullStat }>(type, 'getchannelstat', body)
    }

    static async testChannel(type: string, body: BaseBoardRequest<BaseChannelRequest>) {
        return BaseApi.sendRequest<{status: TestChannelStateCode, channel: SlimChannelInfo}>(type, 'testchannel', body)
    }

    static async getChannelSlots(type: string, body: BaseBoardRequest<{ channel_ids: Array<ChannelInfo['id']> }>) {
        return BaseApi.sendRequest<{items: Array<ChannelSlot>}>(type, 'getchannelslots', body)
    }

    static async clearUsersRequest(type: string, body: ClearUsersRequestBody) {
        return BaseApi.sendRequest<ClearUsersGetActiveRequestResponse | ClearUsersRequest>(type, 'ClearUsersRequest', body)
    }

    static async deleteChannel(type: string, body: BaseBoardRequest<{ id: number }>) {
        return BaseApi.sendRequest<void>(type, 'deletechannel', body)
    }
}
