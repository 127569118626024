
































import { SendMessageRequest } from "./SendMessageRequest";
import { InputSetups } from "@/mixins/input-setups";

import MultiMessageEditorWithMediaInput
  from "piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue";

import { Component, Mixins, VModel } from 'vue-property-decorator';

@Component({
  components: {
    MultiMessageEditorWithMediaInput
  }
})
export default class SendMessageRequestSetting extends Mixins(InputSetups) {
  @VModel() model!: SendMessageRequest
}
