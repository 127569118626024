import BaseApi from '@/includes/services/BaseApi'
import {
	Product,
	ProductState,
	EditProductModel,
	CreateProductModel,
} from 'piramis-base-components/src/components/Shop/Products/types'
import {
	CashRegisterModel,
	CashRegistersState,
	CreateCashRegisterModel,
	UpdateCashRegisterModel,
} from 'piramis-base-components/src/components/Shop/CashRegisters/types'
import { Notice } from 'piramis-base-components/src/components/Shop/Notices/types'
import { Report } from 'piramis-base-components/src/components/Shop/ProductReports/types'
import { BaseBoardRequest } from "@/includes/types/Api.types";

export class ShopService {
	static async createProduct(type: string, body: BaseBoardRequest<CreateProductModel>) {
		return BaseApi.sendRequest<{ data: Product }>(type, 'CreateProduct', body)
	}

	static async deleteProduct(type: string, body: BaseBoardRequest<{ id: number }>) {
		return BaseApi.sendRequest<{ state: ProductState.Hidden | ProductState.Removed }>(type, 'DeleteProduct', body)
	}

	static async editProduct(type: string, body: BaseBoardRequest<EditProductModel>) {
		return BaseApi.sendRequest<{ data: Product }>(type, 'EditProduct', body)
	}

	static async getProducts(type: string, body: BaseBoardRequest) {
		return BaseApi.sendRequest<{ items: Array<Product> }>(type, 'GetProducts', body)
	}

	static async getCashRegistries(type: string, body: BaseBoardRequest) {
		return BaseApi.sendRequest<{ items: Array<CashRegisterModel> }>(type, 'GetCashRegistries', body)
	}

	static async createCashRegister(type: string, body: BaseBoardRequest<CreateCashRegisterModel>) {
		return BaseApi.sendRequest<{ data: CashRegisterModel }>(type, 'CreateCashRegister', body)
	}

	static async editCashRegister(type: string, body: BaseBoardRequest< UpdateCashRegisterModel>) {
		return BaseApi.sendRequest<{ data: CashRegisterModel }>(type, 'EditCashRegister', body)
	}

	static async deleteCashRegister(type: string, body: BaseBoardRequest<{ id: number }>) {
		return BaseApi.sendRequest<{ state: CashRegistersState }>(type, 'DeleteCashRegister', body)
	}

	static async getNotices(type: string, body: BaseBoardRequest<{ offset: number, limit: number, customer_id?: number, pay_status?: any }>) {
		return BaseApi.sendRequest<{ items: Array<Notice>, count: number }>(type, 'GetNotices', body)
	}

	static async removeNotice(type: string, body: BaseBoardRequest<{ id: number }>) {
		return BaseApi.sendRequest<void>(type, 'DeleteNotice', body)
	}

	static async getProductsReport(type: string, body: BaseBoardRequest<{ customer_id?: number, product_id?: number }>) {
		return BaseApi.sendRequest<{ items: Array<Report> }>(type, 'GetProductsReport', body)
	}

	static async restoreProduct(type: string, body: BaseBoardRequest<{ id: number }>) {
		return BaseApi.sendRequest<void>(type, 'RestoreProduct', body)
	}

	static async restoreCashRegister(type: string, body: BaseBoardRequest<{ id: number }>) {
		return BaseApi.sendRequest<void>(type, 'RestoreCashRegister', body)
	}

	static async refundPayment(type: string, body: BaseBoardRequest<{ id: number }>) {
		return BaseApi.sendRequest<void>(type, 'refundpayment', body)
	}
}
