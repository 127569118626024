import BaseApi from '@/includes/services/BaseApi'
import {
    Board, BoardAdmin,
    BoardAdminPermissions,
    BoardAdmins,
    BoardsStat,
    SlimBoardInfo,
    TestBoardStateCode
} from '@/includes/types/Board.types'
import { BaseBoardRequest } from "@/includes/types/Api.types";
import { SlimChannelInfo } from "@/includes/types/Channel.types";

export class BoardsApi {
    static async createBoard(type: string, body: { token: string, timezone:string }) {
        return BaseApi.sendRequest<SlimBoardInfo>(type, 'createBoard', body)
    }

    static async deleteBoard(type: string, body: BaseBoardRequest) {
        return BaseApi.sendRequest(type, 'deleteboard', body)
    }

    static async getBoards(type: string) {
        return BaseApi.sendRequest<{ boards: Array<SlimBoardInfo> }>(type, 'getboards', undefined)
    }

    static async getBoardChannels(type: string, body: BaseBoardRequest) {
        return BaseApi.sendRequest<{ board: SlimBoardInfo, channels: Array<SlimChannelInfo> }>(type, 'getBoardChannels', body)
    }

    static async getPostBoardConfig(type: string, body: BaseBoardRequest) {
        return BaseApi.sendRequest<Board>(type, 'getPostBoardConfig', body)
    }

    static async setPostBoardConfig(type: string, body: BaseBoardRequest<Pick<Board, 'lang' | 'timezone' | 'config'>>) {
        return BaseApi.sendRequest<Board>(type, 'setPostBoardConfig', body)
    }

    static async getBoardsStat(type: string, body: {}) {
        return BaseApi.sendRequest<{ boards: BoardsStat }>(type, 'getboardsstat', body)
    }

    static async addBoardAdminByLink(type: string, body: BaseBoardRequest<{ permissions: BoardAdminPermissions, channel_ids: Array<number> | null, token?:string }>) {
        return BaseApi.sendRequest<{link?:string, token?:string, complete?:boolean, admins?:Array<BoardAdmin>}>(type, 'addboardadminbylink', body)
    }

    static async addBoardAdminByName(type: string, body: BaseBoardRequest<{ username: string,  permissions: BoardAdminPermissions, channel_ids: Array<number> | null }>) {
        return BaseApi.sendRequest<BoardAdmins>(type, 'addboardadminbyname', body)
    }

    static async deleteBoardAdmin(type: string, body: BaseBoardRequest<{ user_id: number }>) {
        return BaseApi.sendRequest<BoardAdmins>(type, 'deleteboardadmin', body)
    }

    static async updateBoardAdmin(type: string, body: BaseBoardRequest<{ user_id: number, permissions: BoardAdminPermissions, channel_ids: Array<number> | null }>) {
        return BaseApi.sendRequest<{ admins: BoardAdmins }>(type, 'updateboardadmin', body)
    }

    static async testPostBoard(type: string, body: BaseBoardRequest) {
        return BaseApi.sendRequest<{ status: TestBoardStateCode }>(type, 'testpostboard', body)
    }

    static async activateBoardLicence(type: string, body: BaseBoardRequest) {
        return BaseApi.sendRequest<{ board: SlimBoardInfo }>(type, 'ActivateBoardLicence', body)
    }
}
