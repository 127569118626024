import { ArticlesApi } from '@/includes/Api/Articles.api'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import { RootState } from "@/store/state";
import { Article, CreatedArticle } from "@/includes/types/Articles.types";

import { Commit, Dispatch } from 'vuex'

export default {
    requestArticles({ commit, state }: { commit: Commit, state: RootState }) {
        return ArticlesApi.getArticlesInfo('tg', { board_key: state.boardsState.activeBoard!.board })
            .then((data) => {
                commit('SET_ARTICLES_TO_STORE', data.items)

                return data.items
            })
            .catch((error: any) => {
                errorNotification(error)

                commit('SET_ARTICLES_TO_STORE', [])
            })
    },
    removeArticle({ commit, state }: { commit: Commit, state: RootState }, key: CreatedArticle['key']) {
        return ArticlesApi.deleteArticle('tg', { board_key: state.boardsState.activeBoard!.board, key })
            .then((res) => {
                commit('REMOVE_ARTICLE', res.key)

                successNotification()
            })
            .catch(errorNotification)
    },
    addArticle({ commit, state }: { commit: Commit, state: RootState }, payload: Article) {
        return ArticlesApi.createArticle('tg', {
            board_key: state.boardsState.activeBoard!.board,
            title: payload.title,
            article: payload.article
        })
            .then((res) => {
                commit('ADD_ARTICLE', res)

                successNotification()

                return res
            })
            .catch(errorNotification)
    },
    updateArticle({ commit, state, dispatch }: {
        commit: Commit,
        dispatch: Dispatch,
        state: RootState
    }, payload: CreatedArticle) {
        return ArticlesApi.updateArticle('tg', {
            board_key: state.boardsState.activeBoard!.board,
            key: payload.key,
            title: payload.title,
            article: payload.article
        })
            .then((res) => {
                if (state.articlesState.articles === null) {
                    return dispatch('requestArticles')
                        .then(() => commit('UPDATE_ARTICLE_BY_KEY', res))
                } else {
                    commit('UPDATE_ARTICLE_BY_KEY', res)
                }

                successNotification()
            })
            .catch(errorNotification)
    }
}
