import { Style } from '@/includes/types/Post.types'
import { Advert } from '@/includes/logic/Offers/types'

import { BotProperty, Property } from 'piramis-base-components/src/components/BotProperties/components/Properties/types'
import { BotPmConfig } from "piramis-base-components/src/shared/modules/BotFeedback/types";
import { ChannelInfo, ChannelInfoStatistics } from './Channel.types';

export type Permissions = Record<'create' |
    'hold' |
    'view' |
    'edit' |
    'access_published' |
    'limit_published', boolean>

export type BoardAdminPermissions = Permissions | null

export type BoardAdmin = {
    user_id: number,
    username?: string,
    name: string
    channel_ids: Array<number> | null
    limits: BoardAdminPermissions
}

export type BoardPermissions =
        "ChangeConfig" |
        "CreatePost" |
        "IgnoreHold" |
        "ViewOtherPosts" |
        "EditPosts" |
        "AccessPublishedPosts" |
        "IgnorePublishedPostsAccessLimits"

export type Board = {
    board: string
    title: string
    avatar: string
    timezone: string
    lang: LangCode
    targets: Array<string>,
    test_state: TestBoardStateCode
    license_time: string,
    license_type: number,
    /** был ли активировна пробный Prime */
    license_activated: boolean,
    license_expired: boolean,
    config_version: number,
    features_version: number,
    permissions: Array<BoardPermissions>,
    channels: Array<ChannelInfo>,
    weight: number,
    properties: Array<BotProperty>,
    config: BoardConfig
    admins: Array<BoardAdmin>,
}

export type SlimBoardInfo = Omit<Board, 'config' | 'admins'>

export type BoardConfig = {
    styles: Record<string, Style> | null
    pm_config: BotPmConfig
    editor_hold_time:number
    editor_max_post_access_time:number
    /**
     @deprecated
     */
    properties: Array<Omit<Property, 'group'>>
    labels: Record<number, string>
    ads: Array<Advert>
}

export type BoardWithStatistics = Omit<SlimBoardInfo, 'weight' | 'targets'>
    & { targets_stat: Array<ChannelInfoStatistics> }
    & { targets: Array<ChannelInfoStatistics> }

export type BoardsStat = Array<BoardWithStatistics>

export type BoardAdmins = Pick<Board, 'admins'>

export enum TargetState {
    Board = 'Board',
    Log = 'Log',
    None = 'None',
    BotLeft = 'BotLeft'
}

type LangCode = 'ru' | 'en'

export enum TestBoardStateCode {
    Success = 'Success',
    None = 'None',
    BotDomainError = 'BotDomainError',

    UndefinedError = 'UndefinedError',
    HandlerError = 'HandlerError',
    ApiError = 'ApiError',
    CommunicationError = 'CommunicationError',
}

