import { RootState } from '@/store/state'

export default {
	propertiesList(state: RootState) {
		const boardProperties = state.boardsState?.activeBoard?.properties ?? []
		const systemProperties = state?.AdminTemplate?.runtime_config?.system_properties ?? []

		return [
			...boardProperties.map((prop) => Object.assign(prop, { group: 'custom' })),
			...systemProperties.map((prop) => Object.assign(prop, { group: 'system' })),
		]
	}
}
