import { RootState } from '@/store/state'
import {
	BotProperty,
} from 'piramis-base-components/src/components/BotProperties/components/Properties/types'

export default {
	update_board_properties(state: RootState, properties: Array<BotProperty>):void {
		if (state.boardsState.activeBoard) {
			state.boardsState.activeBoard.properties = properties
		}
	}
}
